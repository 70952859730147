<template>
  <div>
    <el-row align="center">
      <el-col :span="4">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" label-width="68px">
          <el-form-item label="订单单号" prop="oddId">
            <el-input
                v-model="queryParams.oddId"
                placeholder="请输入订单单号"
                clearable
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="4">
        <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" label-width="68px">
          <el-form-item label="运单号" prop="oddNumber">
            <el-input
                v-model="queryParams.oddNumber"
                placeholder="请输入运单号"
                clearable
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="1">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
      </el-col>
      <el-col :span="5">
        <el-upload
            ref="upload"
            class="upload-demo"
            action="https://trackingall.net/api/order/importOrder"
            :on-success="upLoadSuccess"
            :file-list="fileList"
            :show-file-list="false"
        >
          <el-button type="primary" size="mini">点击上传CSV文件</el-button> <!-- 自定义上传按钮 -->
        </el-upload>
      </el-col>
    </el-row>




    <el-table :data="orderData">
      <el-table-column prop="oddId" label="订单单号" width="180"></el-table-column>
      <el-table-column prop="startAddress" label="起始地址" width="200"></el-table-column>
      <el-table-column prop="stopAddress" label="终点地址" width="200"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
      <el-table-column prop="oddNumber" label="物流单号" width="200"></el-table-column>
    </el-table>

    <PaginationComponent
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import PaginationComponent from "@/components/Pagination/index.vue";
import {Notification} from "element-ui";

export default {
  components: {PaginationComponent},
  setup() {
    const fileList = ref([]);

    let total = ref(0);

    const handleQuery = () => {
        getList();
    }

    // 使用 ref() 创建响应式对象
    const queryParams = ref({
      oddId: '',
      oddNumber: '',
      pageNum: 1,
      pageSize: 10,
    });


    let orderData = ref([]);

    const getList = () => {
      console.log("执行getList参数");
      listData(queryParams.value).then(response => {
        orderData.value = response.rows;
        total.value = response.total;
        Notification.success("查询成功！")
      });
    }


    const listData = (params) => {
      console.log(params);
      // 将参数对象转换为查询字符串
      const queryString = new URLSearchParams(params).toString();
      console.log("参数------------",queryString);
      return new Promise((resolve, reject) => {
        fetch(`https://trackingall.net/api/order/listData?${queryString}`, {
          method: 'GET',  // 使用 GET 方法
          headers: {
            'Content-Type': 'application/json'  // 设置请求头，虽然 GET 请求通常不需要设置 Content-Type
          }
        })
            .then(response => {
              // 检查响应状态
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();  // 解析响应体为 JSON
            })
            .then(data => {
              // 返回获取的数据
              resolve(data);
            })
            .catch(error => {
              console.error("获取数据失败:", error);
              reject(error);
            });
      });
    };

    const upLoadSuccess = (response, file) => {
      if (file != null) {
        if (response.code !== 0) {
          Notification.error("上传失败！！请稍后重试。。")
          console.log(response.code + response + file);
        } else {
          Notification.success(response.data)
          getList();
        }
      }
    };
    // 在组件创建时调用 getList
    onMounted(() => {
      getList();
    });

    return {
      handleQuery,
      total,
      orderData,
      queryParams,
      fileList,
      getList,
      upLoadSuccess,
    };
  },
};
</script>
<style scoped>

.search-and-upload {
  display: flex;
  justify-content: space-between;
}

.el-table::before {
  background-color: transparent !important;
}

.upload-demo {
  margin-left: 8px;
}


</style>


