import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n';
//富文本1
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

Vue.use(VueI18n);


const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en :{
      Title: 'Global Logistics Tracking Platform',
      littleTitle: 'Supports tracking of over 2100 carriers and 190 airlines worldwide',
      Carrier: 'Carrier',
      GiveCarrier: 'Automatically Recognized by Default',
      Explain: 'ENTER ONE TRACKING NUMBER PER LINE,UP TO 40 TRACKING NUMBERS ALL OWED;SUPPORTS AIR CARGO TRACKING',
      Search: 'Search',
      twoTitle: 'Recommended by cross-border logistics providers',
      ThreeTitle: 'Choose high-quality logistics providers, improve the package track, improve the user shopping experience.',
      api: 'API',
      CarrierEnrollment: 'Carrier Enrollment',
      help: 'Help'
    },
    zh: {
      Title: '全球物流查詢平台',
      littleTitle: '支援查詢全球2100+運輸商、190+航空公司',
      Carrier: '運輸商',
      GiveCarrier: '(默認自動識別)',
      Explain: '每行输入一個單號,最多只允許提交40個單號;支援航空查询.',
      Search: '查詢',
      twoTitle: '跨境物流商推薦',
      ThreeTitle: '選擇高品質的物流商,完善包裹軌跡,提升用戶購物體驗.',
      api: 'API',
      CarrierEnrollment: '運輸商入駐',
      help: '幫助'
    },
    fr: {
      Title: 'Plateforme de suivi logistique mondial',
      littleTitle: 'Support pour la recherche de plus de 2100 transporteurs dans le monde, 190 compagnies aériennes',
      Carrier: 'Transporteur',
      GiveCarrier: '(Détection automatique par défaut)',
      Explain: 'SAISISSEZ UN NUMERO DE SUIVI PAR LIGNE. VOUS POUVEZ SOUMETTRE JUSQU Ã 40 NUMÉROS DE SUIVI. LA RECHERCHE AÉRIENNE EST PRISE EN CHARG',
      Search: 'Rechercher',
      twoTitle: 'Recommandation de prestataires de logistique transfrontaliers',
      ThreeTitle: 'Choisissez des prestataires de logistique de qualité, améliorez le suivi des colis et augmentez l expérience dachat des clients.',
      api: 'API',
      CarrierEnrollment: 'Inscription des transporteurs',
      help: 'Aide'
    },
    de: {
      Title: 'Weltweite Logistikplattform',
      littleTitle: 'Unterstützung bei der Suche nach über 2100 Speditionen weltweit, 190 Fluggesellschaften',
      Carrier: 'Spedition',
      GiveCarrier: '(Standardmäßig automatische Erkennung)',
      Explain: 'GEBEN SIE PRO ZEILE EINE SENDUNGSNUMMER EIN. ES KONNEN MAXIMAL 40SENDUNGSNUMMERN EINGEREICHT WERDEN. LUFTFRACHTSUCHE WIRD UNTERSTUTZT',
      Search: 'Suche',
      twoTitle: 'Empfehlung grenzüberschreitender Logistikdienstleister',
      ThreeTitle: 'Wählen Sie qualitativ hochwertige Logistikdienstleister aus, verbessern Sie die Paketverfolgung und steigern Sie das Einkaufserlebnis der Kunden.',
      api: 'API',
      CarrierEnrollment: 'Versandunternehmen anmelden',
      help: 'Hilfe'
    },
    es: {
      Title: 'Plataforma global de seguimiento logístico',
      littleTitle: 'Soporte para la búsqueda de más de 2100 transportistas en todo el mundo, 190 aerolíneas',
      Carrier: 'Transportista',
      GiveCarrier: '(Detección automática por defecto)',
      Explain: 'INGRESE UN NUMERO DE SEGUIMIENTO POR LINEA. PUEDE ENVIAR HASTA 40NUMEROS DE SEGUIMIENTO. SE ADMITE LA BÚSQUEDAAÉREA',
      Search: 'Buscar',
      twoTitle: 'Recomendación de proveedores de logística transfronteriza',
      ThreeTitle: 'Elija proveedores de logística de calidad, mejore el seguimiento de paquetes y aumente la experiencia de compra de los clientes.',
      api: 'API',
      CarrierEnrollment: 'Registro de transportistas',
      help: 'Ayuda'
    }
  }
})

Vue.config.productionTip = false
import axios from "axios"
axios.defaults.baseURL="api"
Vue.prototype.$http = axios

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
