// file:api.js
// 1、导入axios以及拦截器
import service from './request'
import {config} from "@vue/cli-plugin-eslint/eslintOptions";

// 2、定义登录函数接口
export const userList = (data) => {
    return service({
        url: '/test/list',
        method: 'post',
        data
    })
};
export const selectIdOrder = (data) => {
    return service({
        url: '/order/selectOrder?oddNumber='+data,
        method: 'post',
    })
};

