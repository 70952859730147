import Vue from 'vue'
import Router from 'vue-router'
import listItem from '@/components/listItem'
import selectLogistics from '@/components/selectLogistics'
import importOrder from '@/components/importOrder'


Vue.use(Router)

// eslint-disable-next-line no-unused-vars
let router;
// eslint-disable-next-line no-unused-vars
export default router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'listItem',
            component: listItem
        },
        {
            path: '/selectLogistics',
            name: 'selectLogistics',
            component: selectLogistics
        },
        {
            path: '/importOrder',
            name: 'importOrder',
            component: importOrder
        },

    ]
})
