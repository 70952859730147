<template>
  <div class="container">
    <div class="navbar">
      <div class="navbar_header flex items-center cursor-pointer relative" style="height: 66px;">
          <img src="../assets/phone.png" draggable="false" alt="Logo" style="height: 60px; width: 107px;">
        <div class="flex items-center ml-auto" style="height: 66px;">
          <div class="pad flex items-center language-font">{{$t('api')}}</div>
          <div class="pad flex items-center language-font">{{$t('CarrierEnrollment')}}</div>
          <div class="pad flex items-center language-font">{{$t('help')}}</div>
          <img src="../assets/1.svg" draggable="false" alt="Language Icon" style="width: 16px; height: 16px; margin-right: -5px;">
          <div class="flex updowncenter pad">
            <div class="uni-stat__select">
              <div class="uni-stat-box uni-stat__actived">
                <div class="uni-select">
                  <div class="uni-select__input-box">
                    <select id="language-selector" @change="changeLanguage" v-model="selectedLanguage">
                      <option value="en" >English</option>
                      <option value="zh">繁體中文</option>
                      <option value="de">Deutsch</option>
                      <option value="es">Español</option>
                      <option value="fr">Français</option>
                    </select>
                    <div class="uni-icons uniui-bottom" style="color: rgb(153, 153, 153); font-size: 14px; margin-left: 5px;">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-box">
      <h1 class="title deep-blue">&emsp;&emsp;&emsp;{{$t('Title')}}</h1>
      <p class="description">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{$t('littleTitle')}}</p>

      <form class="input-group" @submit.prevent="onSubmit">

        <textarea
          v-model="oddNumber"
          placeholder="ENTER ONE TRACKING NUMBER PER LINE,UP TO 40 TRACKING NUMBERS ALL OWED;SUPPORTS AIR CARGO TRACKING"
          rows="10"
          cols="50"
          class="input-textarea"></textarea>
       &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; <button type="submit" class="search-button" style="width:400px">{{$t('Search')}}</button>
     </form>

      <div class="carrier-selector">
        <label class="carrier-label">{{$t('Carrier')}}</label>
        <select v-model="selectedCarrier" class="carrier-select">
          <option value="">{{$t('GiveCarrier')}}</option>
        </select>
      </div>
     <br>
      <div class="logo-container" style="width: 700px">
         <h1>&emsp;&emsp;&emsp;&emsp;&emsp;{{$t('twoTitle')}}</h1>

      <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{$t('ThreeTitle')}}</p>
      <div class="logos">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
   <img :src="require('../assets/2.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/3.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/4.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/5.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/6.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/7.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;&emsp;&emsp;&emsp;
   <img :src="require('../assets/8.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/9.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/10.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/11.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/12.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   <img :src="require('../assets/13.svg')" alt="Company Logo 3" class="company-logo" style="width: 100px; height: 100px">&emsp;&emsp;
   </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oddNumber: '',
      selectedCarrier: '',
      selectedLanguage: 'en'

    };
  },
  methods: {
    onSubmit() {

      // 检查输入是否为空
      if (this.oddNumber.trim() === '') {

        let message;

        switch (this.selectedLanguage) {
          case 'en':
            message = 'You have not entered a tracking number!';
            break;
          case 'zh':
            message = '還沒有輸入單號！';
            break;
          case 'de':
            message = 'Sie haben keine Sendungsnummer eingegeben!';
            break;
          case 'es':
            message = '¡No ha ingresado un número de seguimiento!';
            break;
          case 'fr':
            message = 'Vous n\'avez pas saisi de numéro de suivi!';
            break;
          default:
            // eslint-disable-next-line no-unused-vars
            message = 'Unknown language selected.';
        }


        alert(message)
        return; // 阻止进一步的操作
      }
      this.$router.push("/selectLogistics?oddNumber=" + encodeURIComponent(this.oddNumber));
      // 在此处添加实际的查询逻辑
    },

    changeLanguage(event) {
    this.$i18n.locale = event.target.value;
  }

  }
};
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* 使用视口高度单位 */
  }

  .navbar {
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .navbar_header {
    display: flex;
    justify-content: space-between; /* 使用空间分布来让 Logo 居左，其他元素居右 */
    align-items: center;
  }

  .navbar .flex {
    display: flex;
    align-items: center;
  }

  .navbar .pad {
    padding: 0 1em;
  }

  .navbar .language-font {
    font-size: 1.2em;
  }

  .search-box {
    text-align: center;
    margin-top: 2em;
  }

  .title {
    font-size: 2.5em; /* 增加标题字体大小 */
  }

  .deep-blue {
    color: #003366; /* 设置深蓝色 */
  }

  .description {
    margin-bottom: 1em;
  }

  .input-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* 垂直堆叠子元素 */
    gap: 1em; /* 子元素间的间隙 */
  }

  .input-textarea {
    width: 50%;
    resize: none;
    max-width: 100cm; /* 设置最大宽度 */
  }

  .search-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fbbd0a;
    color: white;
    border: none;
    border-radius: 0.5em;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  .carrier-selector {
    margin-top: 1em;
    font-family: Arial, sans-serif;
  }

  .carrier-label {
    display: inline-block;
    margin-right: 0.5em;
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }


  .carrier-select {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s, box-shadow 0.3s;
    width: 200px; /* 根据需要调整宽度 */
  }

  #language-selector {
    border: none; /* 移除边框 */
    outline: none; /* 移除焦点轮廓 */
    background-color: transparent; /* 移除背景颜色 */
    font-size: inherit; /* 保持与父元素相同的字体大小 */
    padding: 0; /* 移除内边距 */
    appearance: none; /* 去除默认下拉箭头 */
    -webkit-appearance: none; /* Chrome/Safari/Firefox */
    -moz-appearance: none; /* Firefox */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"><polygon points="0,0 10,0 5,6"/></svg>'); /* 添加自定义下拉箭头 */
    background-repeat: no-repeat;
    background-position: right center; /* 定位箭头位置 */
    cursor: pointer; /* 更改光标样式 */
  }

  .logos-container {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .logo-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1em; /* 公司 logo 之间的间隔 */
    margin-top: 1em; /* 调整与上面文本的距离 */
  }

  .company-logo {
    max-width: 100px; /* 默认宽度 */
    height: auto;
  }

  @media screen and (max-width: 1000px) {
    .company-logo {
      max-width: 50px; /* 手机端宽度 */
    }
  }

  /* 新增的样式 */
  @media screen and (max-width: 768px) {
    body {
      overflow-x: hidden; /* 避免出现水平滚动条 */
    }

    .title {
      font-size: 1.5em; /* 减少标题字体大小 */
    }

    .description {
      font-size: 0.9em; /* 减少描述字体大小 */
    }

    .carrier-label {
      font-size: 14px; /* 减少选择器标签字体大小 */
    }

    .carrier-select {
      font-size: 14px; /* 减少选择器字体大小 */
    }

    .logo-row {
      justify-content: center; /* 将图片居中排列 */
    }

    .company-logo {
      margin: 0 10px; /* 图片之间的间距 */
    }

    .navbar {
      padding-left: 10px; /* 左侧增加内边距 */
    }

    .navbar_header {
      justify-content: space-around; /* 空间均匀分布 */
    }
  }
  .image-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.image-container img {
  width: 20%; /* 调整宽度以适应您的需求 */
  height: auto;
  margin-bottom: 10px; /* 添加适当的间距 */
}
</style>
