<template>
  <div class="container">
    <div class="navbar">
      <div class="navbar_header flex items-center cursor-pointer relative" style="height: 66px;">
        <a href="https://trackingall.net/">
          <img src="../assets/phone.png" draggable="false" alt="Logo" style="height: 60px; width: 107px;">
        </a>
        <div class="flex items-center ml-auto">
          <div class="pad flex items-center language-font">API</div>
          <div class="pad flex items-center language-font">Carrier Enrollment</div>
          <div class="pad flex items-center language-font">Help</div>
          <img src="../assets/1.svg" draggable="false" alt="Language Icon" style="width: 16px; height: 16px; margin-right: -5px;">
          <div class="flex updowncenter pad">
            <div class="uni-stat__select">
              <div class="uni-stat_box uni-stat__actived">
                <div class="uni-select">
                  <div class="uni-select_input-box">
                    <select id="language-selector">
                        <option value="en">English</option>
                        <option value="zh-cn">繁體中文</option>
                        <option value="de">Deutsch</option>
                        <option value="es">Español</option>
                        <option value="fr">Français</option>
                    </select>
                    <div class="uni-icons uniui-bottom" style="color: rgb(153, 153, 153); font-size: 14px; margin-left: 5px;">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div>

      <img :src="require('../assets/status.png')" alt="Company Logo 1" class="company-logo">
    </div>

    <!-- 快递显示 -->
    <div class="tracking-info">
      <div class="tracking-row">
      &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
       <el-button
          type="success"
          icon="el-icon-check"
          circle
        ></el-button>

        <div class="tracking-id">
          &emsp;
          {{ oddNumber }}
        </div>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
        <div class="location">
          {{ startAddress }}
          <i class="el-icon-right"></i>
          {{ stopAddress }}
        </div>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
        <div class="status">
          {{ activities[0].timestamp }}
        </div>
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
       <div class="isDelivered">Delivered</div>
        <div>
          <img :src="require('../assets/aaa.png')" alt="Company Logo 2" class="company-logo">
        </div>

      </div>
    </div>

  <br>
   <div class="block" v-show="isSelect">
  <el-timeline>
    <el-timeline-item
      v-for="(activity, index) in activities"
      :key="index"
      :icon="activity.icon"
      :type="activity.type"
      :color="activity.color"
      :size="activity.size"
      :timestamp="activity.timestamp">
      {{activity.content}}
    </el-timeline-item>
  </el-timeline>
</div>

  </div>
</template>
<script>
import {selectIdOrder} from "@/api/userApi"
import moment from 'moment'
function addDays(date, hours, minutes, seconds, days, direction) {
  const result = new Date(date);
  if (direction === '-') {
    result.setHours(result.getHours() - hours);
    result.setMinutes(result.getMinutes() - minutes);
    result.setSeconds(result.getSeconds() - seconds);
    result.setDate(result.getDate() - days);
  } else {
    result.setHours(result.getHours() + hours);
    result.setMinutes(result.getMinutes() + minutes);
    result.setSeconds(result.getSeconds() + seconds);
    result.setDate(result.getDate() + days);
  }
  return result;
}
export default {
    mounted(){
            selectIdOrder(this.$route.query.oddNumber).then(res=>{
            this.oddNumber = res.data.data.oddNumber
            this.startAddress = res.data.data.startAddress
            this.stopAddress = res.data.data.stopAddress
            const createTimeFormat = res.data.data.createTime
            const formattedCreateTime =moment(createTimeFormat).format('YYYY-MM-DD HH:mm:ss');
            this.createTime =  formattedCreateTime
            this.activities[11].content = `${this.startAddress} ${this.activities[11].content}`;
            this.activities[11].timestamp = formattedCreateTime
            this.activities[10].timestamp = moment(addDays(this.activities[11].timestamp,2,20,0,4,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[9].timestamp = moment(addDays(this.activities[10].timestamp,1,5,12,3,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[8].timestamp = moment(addDays(this.activities[9].timestamp,5,18,21,2,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[7].timestamp = moment(addDays(this.activities[8].timestamp,8,7,39,4,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[6].timestamp = moment(addDays(this.activities[7].timestamp,1,5,12,3,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[5].timestamp = moment(addDays(this.activities[6].timestamp,4,41,18,3,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[4].timestamp = moment(addDays(this.activities[5].timestamp,6,28,33,2,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[3].timestamp = moment(addDays(this.activities[4].timestamp,3,2,11,3,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[2].timestamp = moment(addDays(this.activities[3].timestamp,4,28,47,2,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[1].timestamp = moment(addDays(this.activities[2].timestamp,3,21,54,4,'+')).format('YYYY-MM-DD HH:mm:ss')
            this.activities[0].timestamp = moment(addDays(this.activities[1].timestamp,5,7,44,2,'+')).format('YYYY-MM-DD HH:mm:ss')
            const dateToFilterBy = moment().subtract(0, 'days'); // 设置过滤日期
            const formattedDate = dateToFilterBy.format('YYYY-MM-DD HH:mm:ss')
            this.filterActivities(formattedDate); // 过滤活动数组
            this.isSelect=true
        })

    },
    methods:{
          filterActivities(formattedDate) {
            this.activities = this.activities.filter((activity) =>
            moment(activity.timestamp).isBefore(formattedDate)
    );
  },

    },
    data(){
     return{

      activities: [{
          content: 'Delivered',
          timestamp: null,
          size: 'large',
          type: 'primary',
          icon: 'el-icon-more'
        }, {
          content: 'Leaving the sorting center,the package is on the way',
          timestamp: null,
          color: '#0bbd87'
        }, {
          content: 'Arrive at the sorting and distribution center',
          timestamp: null,
          size: 'large'
        }, {
          content: 'Leave the proessingcenter,and move to the next facility',
          timestamp: null
        },{
          content: 'Package received at processing center',
          timestamp: null
        },{
          content: 'Delivery to local courier company',
          timestamp: null
        },{
          content: 'Import customs clearance',
          timestamp: null
        },
        {
          content: 'Arrive at destination of origin domestic',
          timestamp: null
        },
        {
          content: 'Arrival at domestic internationality airport',
          timestamp: null
        },
        {
          content: 'Goods leave the operation center',
          timestamp: null
        },
        {
          content: 'Arrive at the receiving point',
          timestamp: null
        },
        {
          content: 'Order information received',
          timestamp: null
        },],

      oddNumber:null,
      startAddress:null,
      stopAddress:null,
      createTime:null,
      isSelect:false
     }
    },
};
</script>

<style scoped>
body {
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* 使用视口高度单位 */
}

.navbar_header {
  display: flex;
  justify-content: space-between; /* 使用空间分布来让 Logo 居左，其他元素居右 */
  align-items: center;
}

.navbar .flex {
  display: flex;
  align-items: center;
}

.navbar .pad {
  padding: 0 1em;
}
.navbar {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* 状态图片 */
.logo-container {
  display: flex;
  justify-content: flex-start; /* 靠左对齐 */
  align-items: center;

  padding: 1em; /* 可选：增加一些内边距 */
}

.company-logo {
  width: auto; /* 自动宽度 */
  height: auto; /* 自动高度 */
  max-width: 100%; /* 最大宽度为100%，防止图片过大 */
}

.tracking-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 5px solid #ccc;
}

.tracking-row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap; /* 默认不换行 */
}


.tracking-id,
.location,
.status,
.isDelivered {
  color: #000;
  font-size: 12px;
  flex: 1; /* 平均分配可用空间 */
  white-space: nowrap; /* 默认不换行 */
}




#language-selector {
  border: none; /* 移除边框 */
  outline: none; /* 移除焦点轮廓 */
  background-color: transparent; /* 移除背景颜色 */
  font-size: inherit; /* 保持与父元素相同的字体大小 */
  padding: 0; /* 移除内边距 */
  appearance: none; /* 去除默认下拉箭头 */
  -webkit-appearance: none; /* Chrome/Safari/Firefox */
  -moz-appearance: none; /* Firefox */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"><polygon points="0,0 10,0 5,6"/></svg>'); /* 添加自定义下拉箭头 */
  background-repeat: no-repeat;
  background-position: right center; /* 定位箭头位置 */
  cursor: pointer; /* 更改光标样式 */
}

@media (max-width: 1000px) { /* 这里的值可以根据实际情况进行调整 */
  .location-time {
    flex-wrap: wrap;
  }
}
/* 输入框居中 */
.centered-input {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

</style>
